/**
 * @license
 * MyFonts Webfont Build ID 3433845, 2017-08-08T06:38:16-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: URWGeometric-Thin by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/thin/
 * 
 * Webfont: URWGeometric-ExtraLight by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/extra-light/
 * 
 * Webfont: URWGeometric-Light by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/light/
 * 
 * Webfont: URWGeometric-Regular by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/regular/
 * 
 * Webfont: URWGeometric-Medium by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/medium/
 * 
 * Webfont: URWGeometric-SemiBold by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/semi-bold/
 * 
 * Webfont: URWGeometric-Bold by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/bold/
 * 
 * Webfont: URWGeometric-ExtraBold by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/extra-bold/
 * 
 * Webfont: URWGeometric-Heavy by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/heavy/
 * 
 * Webfont: URWGeometric-Black by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/black/
 * 
 * Webfont: URWGeometric-ThinOblique by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/thin-oblique/
 * 
 * Webfont: URWGeometric-ExtraLightOblique by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/extra-light-oblique/
 * 
 * Webfont: URWGeometric-LightOblique by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/light-oblique/
 * 
 * Webfont: URWGeometric-BlackOblique by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/black-oblique/
 * 
 * Webfont: URWGeometric-BoldOblique by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/bold-oblique/
 * 
 * Webfont: URWGeometric-RegularOblique by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/regular-oblique/
 * 
 * Webfont: URWGeometric-ExtraBoldOblique by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/extra-bold-oblique/
 * 
 * Webfont: URWGeometric-HeavyOblique by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/heavy-oblique/
 * 
 * Webfont: URWGeometric-MediumOblique by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/medium-oblique/
 * 
 * Webfont: URWGeometric-SemiBoldOblique by URW++
 * URL: https://www.myfonts.com/fonts/urw/geometric/semi-bold-oblique/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3433845
 * Licensed pageviews: 20,000
 * Webfonts copyright: (URW)++,Copyright 2016 by (URW)++ Design &amp; Development
 * 
 * © 2017 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//caringinbristol.sharepoint.com///caringinbristol.sharepoint.com///hello.myfonts.net/count/346575");

  
// @font-face {font-family: 'URWGeometric-Thin';src: url($assets-path +'346575_0_0.eot');src: url($assets-path +'346575_0_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_0_0.woff2') format('woff2'),url($assets-path +'346575_0_0.woff') format('woff'),url($assets-path +'346575_0_0.ttf') format('truetype'),url($assets-path +'346575_0_0.svg#wf') format('svg');}
 
  
// @font-face {font-family: 'URWGeometric-ExtraLight';src: url($assets-path +'346575_1_0.eot');src: url($assets-path +'346575_1_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_1_0.woff2') format('woff2'),url($assets-path +'346575_1_0.woff') format('woff'),url($assets-path +'346575_1_0.ttf') format('truetype'),url($assets-path +'346575_1_0.svg#wf') format('svg');}
 
  
// @font-face {font-family: 'URWGeometric-Light';src: url($assets-path +'346575_2_0.eot');src: url($assets-path +'346575_2_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_2_0.woff2') format('woff2'),url($assets-path +'346575_2_0.woff') format('woff'),url($assets-path +'346575_2_0.ttf') format('truetype'),url($assets-path +'346575_2_0.svg#wf') format('svg');}
 
  
@font-face {font-family: 'URWGeometric';src: url($assets-path +'346575_3_0.eot');src: url($assets-path +'346575_3_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_3_0.woff2') format('woff2'),url($assets-path +'346575_3_0.woff') format('woff'),url($assets-path +'346575_3_0.ttf') format('truetype'),url($assets-path +'346575_3_0.svg#wf') format('svg');
font-weight: normal;
font-style: normal;}
 
  
// @font-face {font-family: 'URWGeometric-Medium';src: url($assets-path +'346575_4_0.eot');src: url($assets-path +'346575_4_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_4_0.woff2') format('woff2'),url($assets-path +'346575_4_0.woff') format('woff'),url($assets-path +'346575_4_0.ttf') format('truetype'),url($assets-path +'346575_4_0.svg#wf') format('svg');}
 
  
// @font-face {font-family: 'URWGeometric-SemiBold';src: url($assets-path +'346575_5_0.eot');src: url($assets-path +'346575_5_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_5_0.woff2') format('woff2'),url($assets-path +'346575_5_0.woff') format('woff'),url($assets-path +'346575_5_0.ttf') format('truetype'),url($assets-path +'346575_5_0.svg#wf') format('svg');}
 
  
@font-face {font-family: 'URWGeometric';src: url($assets-path +'346575_6_0.eot');src: url($assets-path +'346575_6_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_6_0.woff2') format('woff2'),url($assets-path +'346575_6_0.woff') format('woff'),url($assets-path +'346575_6_0.ttf') format('truetype'),url($assets-path +'346575_6_0.svg#wf') format('svg');
font-weight: bold;
font-style: normal;}
 
  
// @font-face {font-family: 'URWGeometric-ExtraBold';src: url($assets-path +'346575_7_0.eot');src: url($assets-path +'346575_7_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_7_0.woff2') format('woff2'),url($assets-path +'346575_7_0.woff') format('woff'),url($assets-path +'346575_7_0.ttf') format('truetype'),url($assets-path +'346575_7_0.svg#wf') format('svg');}
 
  
// @font-face {font-family: 'URWGeometric-Heavy';src: url($assets-path +'346575_8_0.eot');src: url($assets-path +'346575_8_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_8_0.woff2') format('woff2'),url($assets-path +'346575_8_0.woff') format('woff'),url($assets-path +'346575_8_0.ttf') format('truetype'),url($assets-path +'346575_8_0.svg#wf') format('svg');}
 
  
// @font-face {font-family: 'URWGeometric-Black';src: url($assets-path +'346575_9_0.eot');src: url($assets-path +'346575_9_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_9_0.woff2') format('woff2'),url($assets-path +'346575_9_0.woff') format('woff'),url($assets-path +'346575_9_0.ttf') format('truetype'),url($assets-path +'346575_9_0.svg#wf') format('svg');}
 
  
// @font-face {font-family: 'URWGeometric-ThinOblique';src: url($assets-path +'346575_A_0.eot');src: url($assets-path +'346575_A_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_A_0.woff2') format('woff2'),url($assets-path +'346575_A_0.woff') format('woff'),url($assets-path +'346575_A_0.ttf') format('truetype'),url($assets-path +'346575_A_0.svg#wf') format('svg');}
 
  
// @font-face {font-family: 'URWGeometric-ExtraLightOblique';src: url($assets-path +'346575_B_0.eot');src: url($assets-path +'346575_B_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_B_0.woff2') format('woff2'),url($assets-path +'346575_B_0.woff') format('woff'),url($assets-path +'346575_B_0.ttf') format('truetype'),url($assets-path +'346575_B_0.svg#wf') format('svg');}
 
  
// @font-face {font-family: 'URWGeometric-LightOblique';src: url($assets-path +'346575_C_0.eot');src: url($assets-path +'346575_C_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_C_0.woff2') format('woff2'),url($assets-path +'346575_C_0.woff') format('woff'),url($assets-path +'346575_C_0.ttf') format('truetype'),url($assets-path +'346575_C_0.svg#wf') format('svg');}
 
  
// @font-face {font-family: 'URWGeometric-BlackOblique';src: url($assets-path +'346575_D_0.eot');src: url($assets-path +'346575_D_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_D_0.woff2') format('woff2'),url($assets-path +'346575_D_0.woff') format('woff'),url($assets-path +'346575_D_0.ttf') format('truetype'),url($assets-path +'346575_D_0.svg#wf') format('svg');}
 
  
// @font-face {font-family: 'URWGeometric-BoldOblique';src: url($assets-path +'346575_E_0.eot');src: url($assets-path +'346575_E_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_E_0.woff2') format('woff2'),url($assets-path +'346575_E_0.woff') format('woff'),url($assets-path +'346575_E_0.ttf') format('truetype'),url($assets-path +'346575_E_0.svg#wf') format('svg');}
 
  
// @font-face {font-family: 'URWGeometric-RegularOblique';src: url($assets-path +'346575_F_0.eot');src: url($assets-path +'346575_F_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_F_0.woff2') format('woff2'),url($assets-path +'346575_F_0.woff') format('woff'),url($assets-path +'346575_F_0.ttf') format('truetype'),url($assets-path +'346575_F_0.svg#wf') format('svg');}
 
  
// @font-face {font-family: 'URWGeometric-ExtraBoldOblique';src: url($assets-path +'346575_10_0.eot');src: url($assets-path +'346575_10_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_10_0.woff2') format('woff2'),url($assets-path +'346575_10_0.woff') format('woff'),url($assets-path +'346575_10_0.ttf') format('truetype'),url($assets-path +'346575_10_0.svg#wf') format('svg');}
 
  
// @font-face {font-family: 'URWGeometric-HeavyOblique';src: url($assets-path +'346575_11_0.eot');src: url($assets-path +'346575_11_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_11_0.woff2') format('woff2'),url($assets-path +'346575_11_0.woff') format('woff'),url($assets-path +'346575_11_0.ttf') format('truetype'),url($assets-path +'346575_11_0.svg#wf') format('svg');}
 
  
// @font-face {font-family: 'URWGeometric-MediumOblique';src: url($assets-path +'346575_12_0.eot');src: url($assets-path +'346575_12_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_12_0.woff2') format('woff2'),url($assets-path +'346575_12_0.woff') format('woff'),url($assets-path +'346575_12_0.ttf') format('truetype'),url($assets-path +'346575_12_0.svg#wf') format('svg');}
 
  
// @font-face {font-family: 'URWGeometric-SemiBoldOblique';src: url($assets-path +'346575_13_0.eot');src: url($assets-path +'346575_13_0.eot?#iefix') format('embedded-opentype'),url($assets-path +'346575_13_0.woff2') format('woff2'),url($assets-path +'346575_13_0.woff') format('woff'),url($assets-path +'346575_13_0.ttf') format('truetype'),url($assets-path +'346575_13_0.svg#wf') format('svg');}
 
.mainCallToAction .cta-button,
a.menuMainAlt {
  border: 2px solid $brand-primary;
}
.mainCallToAction .cta-button:not([class*="donate"]) {
  background-color: transparent;
  color: $brand-primary;
  border: 2px solid $brand-primary;
  &:hover {
    background-color: $brand-primary;
    border-color: $brand-primary;
    color: white;
  }
}

#menuAdmin {
  li {
    margin: 0;
    height: 40px;
    display: inline-flex;
    align-items: center;
  }

  .menuAdminSearch {
    order: 5;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    justify-content: flex-end;
    position: relative;

    button {
      position: absolute;
      right: 0;
    }
  }

  #siteSearch {
    max-width: $header-search-input-max-width;
  }

  .socialIcons {
    order: 6;
    margin: 0;

    li {
      margin: 0;
      padding: 0;
    }
  }
}

.pageHeader .headerContent .mainCallToAction {
  margin-left: auto;
}

.homepage-quickgiving--container {
  flex-direction: column;
}

.homepage-quickgiving--copy {
  margin: 0 0 1rem;
}

// Footer

.Footer-Legal {
  text-align: center;
}

// Header mobile fixes
@media (max-width: map-get($breakpoints, sm)) {
  #menuAdmin .menuAdminSearch {
    display: none;
  }
  #menuAdmin .socialIcons {
    display: none;
  }
}